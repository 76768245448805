/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import jogarImg from '../../images/jogar-img.png';
import AppContext from '../../context/AppContext';
import ganheIphone from '../../images/ganheIphone.png';

const importImages = () => {
  const images = {};
  for (let i = 1; i <= 59; i++) {
    const imageName = i.toString();
    images[`image${imageName}`] = require(`../../images/images-sinais-pragmatic/${imageName}.webp`);
  }
  return images;
};

const images = importImages();

export const imagesTwo = [...Object.values(images)];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ImagesArrPragmatic() {
  const { jogos, horario, linkSite, setPromocao } = useContext(AppContext);
  const [imagemAtiva, setImagemAtiva] = useState(null);
  const [open, setOpen] = useState(true);
  const [openGame, setOpenGame] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClickOpenGame = () => {
    setOpenGame(true);
  };
  const handleCloseGame = () => {
    setOpenGame(false);
    setPromocao(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={ handleClose }
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const teste = (number) => {
    if (number >= 70) {
      return (
        <div className="progress lg:w-40 w-28" style={ { height: '25px', fontSize: '20px', position: 'relative' } } role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar progress-bar-striped progress-bar-animated bg-success btn-game" style={ { width: `${number}%` } } />
          <p className="fixed btn-game" style={ { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>{`${number}%`}</p>
        </div>
      );
    } if (number < 70 && number > 30) {
      return (
        <div className="progress lg:w-40 w-28" style={ { height: '25px', fontSize: '20px', position: 'relative' } } role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
          <div className="progress-bar progress-bar-striped progress-bar-animated bg-warning btn-game" style={ { width: `${number}%` } } />
          <p className="fixed btn-game" style={ { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>{`${number}%`}</p>
        </div>
      );
    }
    return (
      <div className="progress lg:w-40 w-28" style={ { height: '25px', fontSize: '20px', position: 'relative' } } role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
        <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger btn-game" style={ { width: `${number}%` } } />
        <p className="fixed btn-game" style={ { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' } }>{`${number}%`}</p>
      </div>
    );
  };

  const testeTwo = (numberOne, numberTwo) => {
    if (numberOne > numberTwo) {
      return (
        <div className="flex flex-col">
          <p className="text-center text-white m-0 btn-game">{`Minutos pagante: ${numberTwo}/${numberOne} `}</p>
          <p className="text-center text-white m-0 btn-game">{`Entrada: ${horario}`}</p>
        </div>
      );
    }
    return (
      <div className="flex flex-col">
        <p className="text-center text-white m-0 btn-game">{`Minutos pagante: ${numberOne}/${numberTwo} `}</p>
        <p className="text-center text-white m-0 btn-game">{`Entrada: ${horario}`}</p>
      </div>
    );
  };

  return (
    <div
      className="flex flex-wrap justify-center bg-black"
    >
      <BootstrapDialog
        sx={ {
          '& .MuiDialog-paper': {
            padding: 0,
            margin: 0, // ou a largura desejada
          },
        } }
        onClose={ handleCloseGame }
        aria-labelledby="customized-dialog-title"
        open={ openGame }
      >
        <DialogContent dividers className="bg-black">
          <button
            onClick={ (e) => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'botao_clicado',
                categoria: 'jogo',
                nome: e, // ou qualquer outra informação relevante
              });
              window.open(linkSite, '_blank');
              setPromocao(true);
            } }
          >
            <img src={ ganheIphone } alt="ganheIphone" />
          </button>
        </DialogContent>
        <DialogContent dividers className="bg-black p-0 flex justify-center items-center">
          <Button
            variant="contained"
            autoFocus
            onClick={ (e) => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'botao_clicado',
                categoria: 'jogo',
                nome: e, // ou qualquer outra informação relevante
              });
              window.open(linkSite, '_blank');
            } }
          >
            JOGAR AGORA
          </Button>
        </DialogContent>
      </BootstrapDialog>
      { jogos.itensTwo?.map((e, i) => (
        <button
          id="minutosvip"
          onMouseEnter={ () => setImagemAtiva(i) }
          onMouseLeave={ () => setImagemAtiva(null) }
          onClick={ () => {
          // setPromocao(true);
            // handleClickOpenGame();
            window.open(linkSite, '_blank');
          // window.dataLayer = window.dataLayer || [];
          // window.dataLayer.push({
          //   event: 'botao_clicado',
          //   categoria: 'jogo',
          //   nome: e, // ou qualquer outra informação relevante
          // });
          // window.open(linkSite, '_blank');
          } }
          className="lg:w-40 w-28 m-2 mb-8 btn-game"
          key={ i }
        >
          <img
            style={ { transitionDuration: '0.5s' } }
            className="lg:w-40 lg:h-40 w-28 h-28 hover:scale-105
                  transform transition-transform btn-game"
            src={ imagemAtiva === i ? jogarImg : imagesTwo[i] }
            alt={ i }
          />
          {
            imagesTwo[i] ? teste(e.number) : ''
          }
          {
            imagesTwo[i] ? testeTwo(e.numberOne, e.numberTwo) : ''
          }
        </button>
      ))}
      <p className="text-gray-800 text-justify m-3">

        AVISO IMPORTANTE: As dicas de horários oferecidas neste site são baseadas em nossa análise e experiência. Embora possam ajudá-lo a aumentar suas chances de sucesso, é importante lembrar que os jogos de azar envolvem riscos e nunca há garantias de vitória. Lembre-se sempre de jogar com responsabilidade, definir limites para suas apostas e nunca excedê-los. O jogo é destinado a ser uma forma de entretenimento e nunca deve ser visto como uma fonte de renda confiável. Ao Clicar em nossos jogos vocês serão encaminhado para nossas salas de sinais gratuitas, temos salas de sinais do fortune tigers, sala de sinais do mines, sala de sinais da roleta, sala de sinais do fortune ox, fortune mouse, sala de sinais do fortune rabbit, sala de sinais do the dog house.
      </p>
    </div>
  );
}
